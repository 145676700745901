@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
  --input-bg: #fbfbfb;
  --input-border: #dddddd;
  --input-radius: 9px;
  --input-padding: 10px 20px;

  --fg: black;
  --bg: white;

  --text-gray: #9d9d9d;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

input {
  border: 1px solid var(--input-border);
  border-radius: var(--input-radius);
  background: var(--input-bg);
  padding: var(--input-padding);
}

button {
  border: 1px solid var(--fg);
  background: var(--fg);
  color: var(--bg);
  padding: var(--input-padding);
  border-radius: var(--input-radius);
  cursor: pointer;
  transition: transform 0.25s ease, box-shadow 0.25s ease, background 0.25s ease;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0);

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.2);
    background: rgb(10, 10, 10);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0);
  }

  &.danger {
    background: rgb(255, 67, 67);
    border: none;
  }
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--default {
  background: var(--fg) !important;
}

.App {
  height: 100vh;
}
