.Auth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .form {
      display: flex;
      flex-direction: column;
      gap: 7px;

      .options {
        font-size: 0.8rem;
        display: flex;
        justify-content: space-between;
        color: var(--text-gray);

        .remember {
          display: flex;
          align-items: center;
          gap: 5px;
        }

        a {
          color: var(--text-gray);
        }
      }
    }

    .seperator {
      width: 100%;
      text-align: center;
      color: var(--text-gray);
      position: relative;

      &::after,
      &::before {
        content: "";
        right: 0;
        width: 110px;
        height: 1px;
        background: var(--text-gray);
        position: absolute;
        top: 50%;
      }

      &::before {
        left: 0;
      }
    }
  }
}
